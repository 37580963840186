<template>
  <div class="warning-push-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form" v-if="dangerid == 0">
            <el-select v-model="listfilter.type" size="medium" @change="onSearch">
              <el-option label="监测点位" :value="1"></el-option>
              <el-option label="监测点" :value="0"></el-option>
            </el-select>

            <el-select v-model="listfilter.dangerid" filterable remote reserve-keyword default-first-option clearable
              placeholder="请选择监测点" size="medium" :remote-method="getDangerList" :loading="getDangerLoading"
              @change="onSearch" style="margin-left: 10px">
              <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
              </el-option>
            </el-select>

            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" size="medium" style="width: 240px; margin-left: 10px" value-format="yyyy-MM-dd"
              @change="dateChange">
            </el-date-picker>
            <el-button style="margin-left: 10px" type="primary" size="medium" icon="el-icon-search"
              @click="onSearch">查询</el-button>
          </el-form>
        </div>
        <div class="action-right">
          <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium" @click="onAdd"
            v-if="userinfo.isAdmin">新增</el-button>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading">
      <el-table-column label="监测点" prop="DangerName" v-if="listfilter.type == 0"></el-table-column>
      <el-table-column label="监测点位" prop="MonitorName" v-if="listfilter.type == 1"></el-table-column>
      <el-table-column label="设备名称" prop="DeviceName" v-if="listfilter.type == 1"></el-table-column>
      <el-table-column label="设备编号" prop="DeviceCode" v-if="listfilter.type == 1"></el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-image style="width: 80px; height: 80px" v-if="scope.row.HeadIcon" :src="scope.row.HeadIcon"
            :preview-src-list="[scope.row.HeadIcon]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="Name"></el-table-column>
      <el-table-column label="手机号码" prop="Phone"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-edit" size="mini" @click="onEdit(scope.row)">修改</el-button>
          <el-button type="warning" icon="el-icon-error" size="mini" style="margin-left: 10px"
            @click="onListDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>

    <el-dialog :title="formtype == 'add' ? '添加接收人' : '修改接收人'" :visible.sync="dialogFormVisible" width="750px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
      :close-on-press-escape="false" @before-close="onFormClose" v-if="dangerid == 0">
      <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px"
        style="padding-top: 20px">
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点" prop="DangerId">
              <el-select v-model="form.DangerId" filterable remote reserve-keyword default-first-option
                placeholder="请选择监测点" size="medium" :remote-method="getDangerList" :loading="getDangerLoading"
                @change="onDangerChange">
                <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点位" prop="MonitorId">
              <el-select v-model="form.MonitorId" filterable remote reserve-keyword default-first-option
                placeholder="请选择监测点位" size="medium" :remote-method="getMonitorList" :loading="getMonitorLoading"
                @change="onMonitorChange">
                <el-option v-for="item in monitorSelect" :key="item.Id" :label="item.MonitorName" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="设备名称" prop="DeviceName">
              <el-input readonly="" v-model="form.DeviceName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="设备编号" prop="DeviceCode">
              <el-input readonly v-model="form.DeviceCode" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="人员姓名" prop="Name">
              <el-input v-model="form.Name" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="手机号码" prop="Phone">
              <el-input v-model="form.Phone" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="头像" prop="HeadIcon">
              <single-file ref="singleFile" text="选择图片" :auto-upload="true" :show-file-list="true"
                :file-list="formHeadIcon" @success="uploadSuccess"></single-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px" @click="onFormClose">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog :title="formtype == 'add' ? '添加接收人' : '修改接收人'" :visible.sync="dialogFormVisible" width="550px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
      :close-on-press-escape="false" @before-close="onFormClose" v-else>
      <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px"
        style="padding-top: 20px">
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="人员姓名" prop="Name">
              <el-input v-model="form.Name" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="手机号码" prop="Phone">
              <el-input v-model="form.Phone" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="头像" prop="HeadIcon">
              <single-file ref="singleFile" text="选择图片" :auto-upload="true" :show-file-list="true"
                :file-list="formHeadIcon" @success="uploadSuccess"></single-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px" @click="onFormClose">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getDangerList, getMonitorList, getMonitorDevice } from "@/api/base";
import {
  getWarningAcceptList,
  addWarningAccept,
  editWarningAccept,
  delWarningAccept,
} from "@/api/device";
import { SingleFile } from "@/components/upload";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,

      filterDaterange: "",
      getDangerLoading: false,
      dangerSelect: [],
      getMonitorLoading: false,
      monitorSelect: [],

      listfilter: {
        type: 1,
        dangerid: "",
        monitorid: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },

      dialogFormVisible: false,
      roleSelect: [],
      formtype: "add",
      formImageList: [],
      form: {
        DangerId: "",
        MonitorId: "",
        DeviceName: "",
        DeviceCode: "",
        Name: "",
        Phone: "",
        HeadIcon: "",
        UserId: "",
        Id: 0,
      },
      formHeadIcon: [],
      formRules: {
        Name: [{ required: true, trigger: "blur", message: "请输入人员姓名" }],
        Phone: [{ required: true, trigger: "blur", message: "请输入手机号码" }],
      },

      dialogDetailVisible: false,
      activeInspectDetail: "",
    };
  },
  props: {
    dangerid: {
      type: Number,
      default: 0,
    },
    loadtype: {
      type: String,
      default: "default",
    },
    monitorInfo: {
      type: Object,
    }
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  components: {
    SingleFile,
  },
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    getMonitorList(value) {
      this.getMonitorLoading = true;
      getMonitorList(1, 100, {
        fname: value,
        dangerid: this.form.DangerId,
      }).then((resdata) => {
        this.getMonitorLoading = false;
        if (resdata.code == 0) {
          this.monitorSelect = resdata.data.data;
        }
      });
    },
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getWarningAcceptList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delWarningAccept(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
    onAdd() {
      this.form = {
        DangerId: "",
        MonitorId: this.loadtype == "default" ? "" : this.monitorInfo.Id,
        Name: "",
        Phone: "",
        HeadIcon: "",
        UserId: "",
        DeviceName: "",
        DeviceCode: "",
        Id: 0,
      };
      this.formtype = "add";
      this.formHeadIcon = [];
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      //   item.DeviceName="";
      //   item.DeviceCode="";
      console.log(JSON.stringify(item));
      this.form = JSON.parse(JSON.stringify(item));
      if (this.form.DangerId == 0) {
        this.form.DangerId = '';
        this.monitorSelect.push({
          Id: this.form.MonitorId,
          MonitorName: this.form.MonitorName,
        })
      } else {
        this.form.MonitorId = '';
        this.getMonitorList();
      }
      this.formtype = "edit";
      this.formHeadIcon = this.form.HeadIcon ? [{
        url: this.form.HeadIcon
      }] : [];
      //this.onMonitorChange(this.form.MonitorId);
      this.dialogFormVisible = true;
    },
    uploadSuccess(file) {
      this.form.HeadIcon = file.path;
    },
    onDangerChange() {
      this.getMonitorList();
      this.form.MonitorId = '';
      this.form.DeviceName = "";
      this.form.DeviceCode = "";
    },
    onMonitorChange(value) {
      getMonitorDevice(value).then(res => {
        if (res.code == 0 && res.data.length > 0) {
          var info = res.data[0];
          this.form.DeviceName = info.DeviceName;
          this.form.DeviceCode = info.DeviceCode;
          return;
        }
        this.form.DeviceName = '';
        this.form.DeviceCode = '';
        this.$message.error("未获取到设备信息!");
      })
    },
    onFormClose() {
      this.getListData();
      this.formHeadIcon = [];
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addWarningAccept(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editWarningAccept(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    if (this.loadtype == "default") {
      this.getDangerList();
      this.getListData();
    }
  },
  watch: {
    dangerid: function () {
      if (this.dangerid > 0) {
        this.listfilter.dangerid = this.dangerid;
        this.listfilter.monitorid = this.monitorInfo.Id;
        this.form.MonitorId = this.monitorInfo.Id;
        this.getListData();
      }
    }
  }
};
</script>